import moment from 'moment';
import AuthenticationClient from '../clients/AuthenticationClient';
import SettingsClient from '../clients/SettingsClient';
import IWeeksInfo from '../models/lossplan/IWeekInfo';
import * as Constants from './Constants';
import Secrets from '@yumi/secrets';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJSON = require('../../../../package.json');

const hasAccessByRole = (roles: string[]) => {
  return AuthenticationClient.hasRole(roles);
};

const capitalizeLetter = ([first, ...rest]: any, lowerRest = false) =>
  first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

const getWeeksInfo = (): IWeeksInfo => {
  const date = new Date();
  const d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const lastDay = d.getDate();
  const weeks = [
    [1, 8],
    [8, 14],
    [15, 21],
    [22, lastDay],
  ];
  const currentDay = date.getDate();
  return {
    current: weeks.findIndex(([x, y]) => currentDay >= x && currentDay <= y),
    weeks,
  };
};

const isStoreManage = () => {
  const userInfo = AuthenticationClient.getInfo();
  return userInfo.store.split(' ').length > 1;
};

const hasAnyAccess = (): boolean => {
  const userScopes = AuthenticationClient.getInfo().scope.split(' ');
  if (userScopes.filter((scope: string) => !scope.includes('viewer')).length > 0) {
    return true;
  }
  return false;
};

export const GetTasksByScopes = () => {
  const userScopes = AuthenticationClient.getInfo().scope.split(' ');
  const normalizedScopes = userScopes.map((scope) =>
    scope.replace(/101010101010101010101:/g, '').toLowerCase()
  );

  return Constants.TasksGetByScopes.filter((task) => {
    const taskScope = task.toLowerCase() + '.write';
    return normalizedScopes.includes(taskScope);
  });
};

const showNotifications = (): boolean => {
  if (!hasAccessByRole(['news.read']) && !isMDH()) return false;

  if (!isMDH()) return true;

  return isEnableShowNotificationNews() && !hasAccessByRole(['updatestore.manager']);
};

const getFormattedDateFromNow = (date: string) => {
  moment.updateLocale('en', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: '1 s',
      ss: '%d s',
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      w: '1 w',
      ww: '%d w',
      M: '1 m',
      MM: '%d m',
      y: '1 y',
      yy: '%d y',
    },
  });
  moment.updateLocale('es', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: '1 s',
      ss: '%d s',
      m: '1 m',
      mm: '%d m',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: '%d d',
      w: '1 w',
      ww: '%d w',
      M: '1 m',
      MM: '%d m',
      y: '1 y',
      yy: '%d y',
    },
  });
  return moment(date).fromNow(true);
};

export const imgUrl = (sku: number | undefined): string => {
  if (sku) {
    return `https://media.easy.cl/is/image/EasySA/${sku}`;
  } else {
    return 'assets/dummy-img.svg';
  }
};

export const isEnableShowNotificationNews = () => {
  const config = SettingsClient.get('REMOTE_CONFIG');
  const { store, businessUnit } = AuthenticationClient.getInfo();
  const validStores =
    config[`show_notification_news_${Secrets.REACT_APP_ENVIRONMENT?.toLowerCase()}`];
  return !validStores || JSON.parse(validStores._value)[businessUnit]?.stores.includes(store);
};

/**
 * check if feature is active in Redis config
 * @param {Record<string,any>} settings
 * @param {string} label
 * @returns
 */
export const isActivedInSettings = (settings: Record<string, any>, label: string) => {
  if (!settings || !Object.keys(settings).length) return false;
  const { store } = AuthenticationClient.getInfo();
  const validStores = settings[`${label}_${Secrets.REACT_APP_ENVIRONMENT?.toLowerCase()}`];
  return validStores?.value?.includes(store) || false;
};

export const isMDH = (): boolean => {
  const businessUnit = AuthenticationClient.getInfo()?.businessUnit?.toUpperCase();
  return businessUnit === Constants.BU_MDH;
};

export const slugify = (str = '') => {
  return str
    .toLowerCase()
    .trim()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

// format to a currency given a number
// example: locales='es-CL', currency='CLP'
export const formatCurrency = (number: number, locales: string, currency: string) => {
  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
  }).format(number);
};

export const isEnablePreStock = () => {
  const config = SettingsClient.get('REMOTE_CONFIG');
  const { store } = AuthenticationClient.getInfo();
  const validStores = config[`enable_pre_stock_${Secrets.REACT_APP_ENVIRONMENT?.toLowerCase()}`];
  return validStores._value.includes(store);
};

// remove a string from a string
export const removeWordFromString = (str: string, strToRemove: string) => {
  return str.split(strToRemove).join('').trimStart();
};

const removeInitialZero = (productId: string): string => Number(productId).toString();

const defaultExport = {
  hasAccessByRole,
  capitalizeLetter,
  getWeeksInfo,
  isStoreManage,
  hasAnyAccess,
  removeInitialZero,
  getFormattedDateFromNow,
  showNotifications,
  isMDH,
};

export const removeFromLocalStore = (key: string) => {
  localStorage.removeItem(key);
};

export const saveToLocalStore = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStore = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}');
  } catch (error) {
    console.log('review error', error);
    return {};
  }
};

export const getArrayFromLocalStore = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '[]');
  } catch (error) {
    console.log('review error', error);
    return [];
  }
};

export const AppVersion = packageJSON.version;

export const resolveNomenclatorLabel = (
  nomenclator: string,
  id: any,
  idKey = 'id',
  labelKey = 'label'
) => {
  const nomenclatorsList = getArrayFromLocalStore(nomenclator);
  const index = nomenclatorsList.findIndex((a: any) => a[idKey] === id);
  return index !== -1 ? nomenclatorsList[index][labelKey] : id;
};

const getVetificatorDigit = (ean: string): number => {
  const digits = ean.split('');
  // Sum of even digits and multiply by 3 [Note: array starts at 1 index]
  const evenSum =
    digits.reduce((sum, digit, index) => sum + ((index + 1) % 2 === 0 ? Number(digit) : 0), 0) * 3;
  // Sum of odd digits [Note: array starts at 1 index]
  const oddSum = digits.reduce(
    (sum, digit, index) =>
      sum + ((index + 1) % 2 === 1 && index + 1 !== digits.length ? Number(digit) : 0),
    0
  );

  const total = (evenSum + oddSum) % 10;
  return total !== 0 ? 10 - total : 0;
};
/**
 * Generates a new ean code for balance products
 * Verification digits are calculated using the following algorithm https://boxshot.com/barcode/tutorials/ean-13-calculator/
 * @param {string} ean
 * @param {string} store
 * @returns {ean: string, queryUrl?: string}
 */
export const getBalanceProductEAN = (
  ean: string,
  store: string
): { ean: string; price?: string; weight?: string } => {
  const eanTrim = ean.trim();
  /* If ean is less than 13 digits, return the same ean */
  if (ean.length < 13) return { ean };
  /* Only ean 24 and 29 are valid */
  if (!eanTrim.startsWith('24') && !eanTrim.startsWith('29')) return { ean };

  if (!store.startsWith('N') && !store.startsWith('J') && !store.startsWith('O')) return { ean };

  /* Split ean in product and price */
  const product = eanTrim.substring(0, 7);
  const price = eanTrim.substring(7);

  /* If store starts with J or O, use normal method */
  const useNormalMethod = store.startsWith('J') || store.startsWith('O');

  let newEan = '';
  if (useNormalMethod) {
    /* Pad product with zeros */
    newEan = `00000${product}`;
    newEan = `${newEan}${getVetificatorDigit(newEan)}`;
  } else {
    /* Remove the first two digits, last digit and pad with zeros */
    const productCode = product.substring(2).slice(0, -1);
    newEan = `${productCode}${getVetificatorDigit(`00000000${productCode}`)}`;
  }

  const priceOrWeight = price.slice(0, -1);

  return {
    ean: newEan,
    price: useNormalMethod ? priceOrWeight : undefined,
    weight: !useNormalMethod ? priceOrWeight : undefined,
  };
};

export default defaultExport;
