import jwt from 'jsonwebtoken';
import { Preferences } from '@capacitor/preferences';
import WithBootedClient from '../lib/WithBootedClient';
import { IJwt } from '../models/IJwt';
import IJwtEntity from '../models/IJwtEntity';
import axios from 'axios';
import md5 from 'md5';
import UserStatusClient from './UserStatusClient';
import { AppVersion, removeFromLocalStore } from '../lib/utils';
import Secrets from '@yumi/secrets';

export async function getAuthFromCache() {
  const cacheAuth = await Preferences.get({ key: userPreferencesKey });
  if (cacheAuth && cacheAuth.value) {
    return JSON.parse(cacheAuth.value!);
  }
  return null;
}

/* Global vars*/
let decodedJwt: IJwtEntity;
const userPreferencesKey = '@user';

const tokenApiURL = Secrets.REACT_APP_TOKEN_API_ENDPOINT;
const apiURL = Secrets.REACT_APP_API;
const allowedDomain = Secrets.REACT_APP_DOMAIN;

interface IState {
  isAuthenticated: boolean;
  user?: IJwt;
  provider: string;
}

class AuthenticationClient extends WithBootedClient {
  state: IState = {
    isAuthenticated: false,
    provider: '',
  };

  async boot() {
    const newState = await getAuthFromCache();
    if (newState) {
      await this.setState(newState);
      this.updateJWT(newState.user);
    }
  }

  updateJWT(token: any): void {
    decodedJwt = jwt.decode(token.access_token) as IJwtEntity;
  }

  isAuthenticated(): boolean {
    return this.state.isAuthenticated;
  }

  getInfo(): IJwtEntity {
    return decodedJwt;
  }

  getAccessToken(): string {
    return this.state.user?.access_token || '';
  }

  async authenticate(provider: string, rawJwt: IJwt) {
    await this.setState({
      isAuthenticated: true,
      user: rawJwt,
      provider: provider,
    });
    decodedJwt = jwt.decode(rawJwt.access_token) as IJwtEntity;
    Preferences.set({
      key: userPreferencesKey,
      value: JSON.stringify(this.state),
    });
  }

  /**
   * Check if the scope in token has the roles in rolesToFind
   * @param rolesToFind
   */
  hasRole(rolesToFind: string[] | string): boolean {
    const rolesToCheck = Array.isArray(rolesToFind) ? rolesToFind : [rolesToFind];
    for (let index = 0; index < rolesToCheck.length; index++) {
      const roleToCheck = rolesToCheck[index];
      if (decodedJwt.scope.includes(roleToCheck)) {
        return true;
      }
    }
    return false;
  }

  async signOut() {
    if (!this.isAuthenticated()) {
      return;
    }
    const user = this.getInfo();
    const options = {
      headers: {
        Authorization: `Bearer ${this.state.user?.access_token}`,
        'X-Frame-Options': 'SAMEORIGIN',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': `default-src 'self' ${allowedDomain}`,
        'App-Version': AppVersion,
        ...(Secrets.REACT_APP_ENVIRONMENT === 'STAGING' && {
          'CF-Access-Client-Id': Secrets.CLOUDFLARE_ACCESS_CLIENT_ID,
          'CF-Access-Client-Secret': Secrets.CLOUDFLARE_ACCESS_CLIENT_SECRET,
        }),
      },
    };
    await axios.post(`${apiURL}/oauth/logout`, { email: user.email }, options);
    await this.removeSession();
  }

  async signIn(email: string, Password: string) {
    const password = md5(Password);
    const appVersion = await UserStatusClient.getAppData();

    const options = {
      headers: {
        'X-Frame-Options': 'SAMEORIGIN',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': `default-src 'self' ${allowedDomain}`,
        'App-Version': AppVersion,
        ...(Secrets.REACT_APP_ENVIRONMENT === 'STAGING' && {
          'CF-Access-Client-Id': Secrets.CLOUDFLARE_ACCESS_CLIENT_ID,
          'CF-Access-Client-Secret': Secrets.CLOUDFLARE_ACCESS_CLIENT_SECRET,
        }),
      },
    };
    return await axios.post(`${apiURL}/oauth/login`, { email, password, ...appVersion }, options);
  }

  async authorizeAdfsToken(tokenData: IJwt) {
    const appVersion = await UserStatusClient.getAppData();
    const options = {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        'X-Frame-Options': 'SAMEORIGIN',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': `default-src 'self' ${allowedDomain}`,
        'App-Version': AppVersion,
        ...(Secrets.REACT_APP_ENVIRONMENT === 'STAGING' && {
          'CF-Access-Client-Id': Secrets.CLOUDFLARE_ACCESS_CLIENT_ID,
          'CF-Access-Client-Secret': Secrets.CLOUDFLARE_ACCESS_CLIENT_SECRET,
        }),
      },
    };
    return await axios.post(`${tokenApiURL}/oauth/login/token/v2`, appVersion, options);
  }

  private async removeSession() {
    await this.setState({
      isAuthenticated: false,
      user: undefined,
      provider: '',
    });
    await Preferences.remove({ key: 'SECURITY_PIN' }); // User Security PIN
    await Preferences.remove({
      key: userPreferencesKey,
    });
    removeFromLocalStore('SAP_USER');
  }

  async setState(newState: IState): Promise<void> {
    this.state = newState;
  }
}

export default new AuthenticationClient();
